import React from 'react';
import {BrowserRouter as Router, Route, Routes, useNavigate} from 'react-router-dom';
import Login from './login';
import Register from './register';
import CloudStorage from './cloudStorage';
import SharedContent from "./sharedContent";
import FileDemoPage from "./fileDemoPage";
import ProjectManager from "./projectManager";
import './App.css';
import Login_MetCraft from "./login_MetCraft";
import JobManagement from "./jobManagement";
import DRPage from "./DR"
import JobManager from "./jobManager";

const Welcome = () => {
    const navigate = useNavigate(); // hook for programmatic navigation

    return (<div>
        <h1>Welcome To MetaboStorage System.</h1>
        <button className="button" onClick={() => navigate('/login')}>
            Login
        </button>
        <button className="button" onClick={() => navigate('/register')}>
            Register
        </button>
    </div>);
};

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Welcome/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/login_omics" element={<Login_MetCraft/>}/>
                <Route path="/register" element={<Register/>}/>
                <Route path="/share-with-me/:storageId" element={<SharedContent/>}/>
                <Route path="/project-manager/:storageId" element={<ProjectManager/>}/>
                <Route path="/file-demo-page" element={<FileDemoPage/>}/>
                <Route path="/storage/:storageId" element={<CloudStorage/>} />
                {/*<Route path="/job-manager/:storageId" element={<JobManager/>} />*/}
                <Route path="/jobs" element={<JobManagement/>}/>
                <Route path="/DR" element={<DRPage/>}/>
            </Routes>
        </Router>
    );
};

export default App;
